<template>
  <div id="footer">
    © 2023 Axel Ollivier
  </div>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped>
#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0;
  height: 50px;
  background-color: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>