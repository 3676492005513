<template>
  <div id="home" class="container column lg">
    <div id="baseline">
      <img src="img/profil.png">
      <h1 class="major-mono">AXel <br/> olliVieR</h1>
      <span class="subtitle">
        Développeur, Entrepreneur, Passionné
      </span>
    </div>
    <div id="duck">
      <img src="img/duck.gif" width="50%">
    </div>
    <div id="content">
      <p>Ce site est en cours de construction, merci de votre compréhension.</p>
      <a href="mailto:axel.ollivier@protonmail.ch">axel.ollivier@protonmail.ch</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  methods: {
    sendMail: function () {

    }
  }
}
</script>
